import sha256 from 'js-sha256';
// Function to loop through and hash values with the 'sha256_' prefix
export function hashObject(data) {
  let hashedData = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      // Handle the 'students' array
      if (key === 'students' && Array.isArray(value)) {
        hashedData['students'] = value.length > 0 ? value.map(student => {
          return {
            'sha256_student_first_name': student.studentFirstName ? sha256(student.studentFirstName.trim()) : "",  // Hash only is defined
            studentBirthYear: student.studentBirthYear || ""  // Keep birth year or empty if undefined
          }
        }) : [];
      }
      // Handle phoneNumber object or string
      else if (key === 'phoneNumber') {
        if (typeof value === 'object') {
          // Handle case where phoneNumber is an object with countryCode, areaCode, and extension
          const concatenatedPhone = `${value.countryCode || ''}-${value.areaCode || ''}-${value.extension || ''}`.trim();
          hashedData['sha256_phone_number'] = sha256(concatenatedPhone);
        } else if (typeof value === 'string') {
          // Handle case where phoneNumber is a simple string
          hashedData['sha256_phone_number'] = sha256(value.trim());
        }
      }
      else if (key === 'emailAddress') {
        hashedData['sha256_email_address'] = sha256(value.trim());
      } else if (key === 'firstName') {
        hashedData['sha256_first_name'] = sha256(value.trim());
      } else if (key === 'lastName') {
        hashedData['sha256_last_name'] = sha256(value.trim());
      } else if (key === 'postalCode') {
        hashedData['sha256_postal_code'] = sha256(value.trim());
      } else {
        hashedData[key] = value;  // Keep non-sensitive fields unchanged
      }
    }
  }
  return hashedData;
}