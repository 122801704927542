export const useLeadGenData = () => {
  const config = useRuntimeConfig()
  
  const transform = (response) => {
    const { sanitize } = useUtils()

    return {
      leadGenFields: sanitize(response.data.attributes.leadGenFields)
    }
  }

  const transformFullWidth = (response) => {
    const { sanitize } = useUtils()

    return sanitize(response.data.attributes)
  }

  const transformRibbonForm = (response) => {
    const { sanitize } = useUtils()

    return {
      ribbonForm: sanitize(response.data.attributes.ribbonForm),
    }
  }

  const transformBrochureRequest = (response) => {
    const { sanitize } = useUtils()

    return {
      ...response.data.attributes,
      heroHeader: sanitize(response.data.attributes.heroHeader),
    }
  }

  const leadGenData = async (deep = true) => {
    const url = `/api/lead-gen`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    });
    
    
  }

  const leadGenFullWidthData = async (deep = true) => {
    const url = `/api/lead-gen-full-width`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformFullWidth
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformFullWidth
    });
    
    
  }

  const ribbonFormData = async (deep = true) => {
    const url = `/api/brochure-request-ribbon-lead`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformRibbonForm
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformRibbonForm
    });
    

  }

  const getLeadGenLocal = async () => {

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/lead-gen', {
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch('/api/lead-gen')
    
  }

  const getBrochureRequestPage = async () => {

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/brochure-request-page', {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformBrochureRequest
      })
    }

    return await useFetch('/api/brochure-request-page', {
      method: 'GET',
      transform: transformBrochureRequest
    })
    
  }

  const setLeadGenLocal = async (data) => {

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/lead-gen', {
        method: 'post',
        baseURL: config.public.nuxtAPIUrl,
        body: data
      })
    }

    return await useFetch('/api/lead-gen', {
      method: 'post',
      body: data
    })
    
  }

  const sendLeadGenRequest = async (data) => {
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/submit-lead-gen', {
        method: 'post',
        baseURL: config.public.nuxtAPIUrl,
        body: data
      })
    }

    return await useFetch('/api/submit-lead-gen', {
      method: 'post',
      body: data
    })
    
  }

  const getCountries = async () => {

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/countries', {
        baseURL: config.public.nuxtAPIUrl,
      })
    }
    
    return await useFetch('/api/countries')
  }

  const sanitizeLeadGenPayload = (formData) => {
    const data = {
      leadTypeID: formData.leadTypeID.value,
      firstName: formData.firstName.value,
      emailAddress: formData.emailAddress.value
    }

    if (formData.postalCode) {
      data.postalCode = formData.postalCode.value;
    }

    if (formData.learningStyleTypeIDs) {
      if (formData.learningStyleTypeIDs.value.length > 0) {
        data.learningStyleTypeIDs = formData.learningStyleTypeIDs.value;
      }
      else {
        data.learningStyleTypeIDs = [405000, 405001, 405002, 405003]
      }
    }

    if (formData.locationName) {
      data.locationName = formData.locationName;
    }
    
    // Only applies for the brochure request page
    if (formData.brochure) {
      data.sendPrintedInformation = formData.brochure.value ? formData.brochure.value.brochureSendPrintedInformation.value : false;
      if (data.sendPrintedInformation) {
        data.firstName = formData.brochure.value.brochureFirstName.value;
        data.lastName = formData.brochure.value.brochureLastName.value;
        data.phoneNumber = { phoneNumber: formData.brochure.value.brochurePhoneNumber.value };
        data.address1 = formData.brochure.value.brochureAddress1.value;
        data.address2 = formData.brochure.value.brochureAddress2.value;
        data.city = formData.brochure.value.brochureCity.value;
        data.state = formData.brochure.value.brochureState.value;
        data.postalCode = formData.brochure.value.brochurePostalCode.value;
        data.country = formData.brochure.value.brochureCountry.value;
      }
      // Add studentBirthYears logic
      if (Array.isArray(formData.studentBirthYears.value) 
          && formData.studentBirthYears.value.length > 0 
          && formData.studentBirthYears.value[0] !== "") {
        data.students = formData.studentBirthYears.value.map(year => ({
          studentFirstName: "",
          studentBirthYear: parseInt(year, 10),
        }));
      } else {
        data.students = []; // Initialize as an empty array if studentBirthYears is not valid
      }
    }

    return data;
  }

  const getLocationShortFormFields = () => {
    return {
      leadTypeID: {
        value: 67008,
        valid: true 
      },
      firstName: {     
        value: '',
        required: true,
        valid: false
      },
      emailAddress: {
        value: '',
        required: true,
        valid: false
      },
      locationName: {
        value: '',
        required: false,
        valid: true
      }
    }
  }

  const getBrochureRequestFormFields = () => {
    
    return {
      leadTypeID: {
        value: 67000,
        valid: true 
      },
      firstName: {     
        value: '',
        required: true,
        valid: false
      },
      emailAddress: {
        value: '',
        required: true,
        valid: false
      },
      postalCode: {        
        value: '',
        required: false,
        valid: true
      },
      studentBirthYears: {        
        value: '',
        required: false,
        valid: true
      },
      learningStyleTypeIDs: {        
        required: false,
        valid: true,        
        value: []
      },
      brochure: {    
        required: true,
        valid: true,
        fields: {
          sendPrintedInformation: {
            value: false,
            valid: false
          },
          firstName: {            
            value: '',
            required: true,
            valid: false
          },
          lastName: {            
            value: '',
            required: true,
            valid: false
          },
          address1: {            
            value: '',
            required: true,
            valid: false
          },
          address2: {            
            value: '',
            required: false,
            valid: true
          },
          city: {            
            value: '',
            required: true,
            valid: false
          },
          state: {           
            value: '',
            required: true,
            valid: false
          },
          postalCode: {            
            value: '',
            required: true,
            valid: false
          },
          country: {           
            value: '',
            required: false,
            valid: true
          },
          phoneNumber: {            
            value: '',
            required: false,
            valid: true
          }    
        }
      }
    }
  }

  return {
    leadGenData,
    ribbonFormData,
    getLeadGenLocal,
    setLeadGenLocal,    
    getBrochureRequestFormFields,
    sanitizeLeadGenPayload, 
    sendLeadGenRequest,
    getBrochureRequestPage,
    getLocationShortFormFields,
    getCountries,
    leadGenFullWidthData
  }
}